import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './utils/textCodeUtils';

import { store } from './store';
import PageView from './router';
import './index.css';
import './assets/iconfont/iconfont.css';
import 'antd/dist/antd.min.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <>
    <Provider store={store}>
      <PageView />
    </Provider>
  </>
);
