import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OperateType {
  operate?: '修改' | '绑定';
  type?: string;
  typeName?: string;
  success?: string;
}

export interface StateType {
  setting: any; // 面包屑数据
  currentOperate: OperateType; // 当前操作
}

/**
 * 当前操作类型
 */
export const type = {
  phone: 'phone',
  password: 'password',
  email: 'email'
};

const initialState: StateType = {
  setting: {
    phone: {
      type: type?.phone,
      typeName: '手机',
      success: '登录创作者中心时，请使用新的手机号码登录'
    },
    password: {
      type: type?.password,
      typeName: '密码',
      success: '现在可以使用邮箱地址和密码登录创作者中心了'
    },
    email: {
      type: type?.email,
      typeName: '邮箱',
      success: '现在可以使用邮箱地址和密码登录创作者中心了'
    }
  },
  currentOperate: {}
};

export const userInfoSettingSlice = createSlice({
  name: 'userInfoSetting',
  initialState,
  reducers: {
    setCurrentOperate: (state, action) => {
      state.currentOperate = action.payload;
    }
  }
});

export const { setCurrentOperate } = userInfoSettingSlice.actions;

export default userInfoSettingSlice.reducer;
