import React, { Suspense } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import routers from './routes';
import Loading from '../views/loading';

interface Props {
  path?: string;
  Component?: React.ComponentType;
  children?: Props[];
  redirect?: string;
}

// 递归函数
const routerViews = (routerItems: Props[]) => {
  if (routerItems && routerItems.length) {
    return routerItems.map(({ path, Component, children, redirect }) => {
      return children && children.length > 0 ? (
        <Route
          path={path}
          key={path}
          element={
            <Suspense fallback={<Loading />}>
              {Component && <Component />}
            </Suspense>
          }>
          {routerViews(children)}
        </Route>
      ) : redirect ? (
        <Route
          path={path}
          key={path}
          element={<Navigate to={redirect} />}></Route>
      ) : (
        <Route
          path={path}
          key={path}
          element={
            <Suspense fallback={<Loading />}>
              {Component && <Component />}
            </Suspense>
          }></Route>
      );
    });
  }
};

const PageView = () => {
  return (
    <HashRouter>
      <Routes>{routerViews(routers)}</Routes>
    </HashRouter>
  );
};
export default PageView;
