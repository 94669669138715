import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducer/userSlice';
import noticeReducer from './reducer/noticeSlice';
import breadcrumbReducer from './reducer/breadcrumbSlice';
import userInfoSettingReducer from './reducer/userInfoSettingSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notice: noticeReducer,
    breadcrumb: breadcrumbReducer,
    userSetting: userInfoSettingReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
