import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  quantity: number | undefined; // 通知数量
}

const initialState: State = {
  quantity: undefined
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setNoticeNumber: (state, action) => {
      state.quantity = action.payload;
    }
  }
});

export const { setNoticeNumber } = noticeSlice.actions;

export default noticeSlice.reducer;
