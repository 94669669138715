import { lazy } from 'react';

interface Props {
  path?: string;
  Component?: React.ComponentType;
  children?: Props[];
  redirect?: string;
}

const routers: Props[] = [
  {
    path: '/',
    Component: lazy(() => import('../layout')),
    children: [
      {
        path: '/',
        Component: lazy(() => import('../views/home'))
      },
      {
        path: 'global',
        Component: lazy(() => import('../views/global')),
        children: [
          {
            path: 'home',
            Component: lazy(() => import('../views/workbench'))
          },
          {
            path: 'works',
            Component: lazy(() => import('../views/works'))
          },
          {
            path: 'idc',
            Component: lazy(() => import('../views/idc'))
          },
          {
            path: 'income',
            Component: lazy(() => import('../views/income'))
          },
          {
            path: 'reward',
            Component: lazy(() => import('../views/reward'))
          },
          {
            path: 'interactive',
            Component: lazy(() => import('../views/interactive'))
          },
          {
            path: 'activity',
            Component: lazy(() => import('../views/activity'))
          },
          {
            path: 'userInfo',
            Component: lazy(() => import('../views/userInfo'))
          },
          {
            path: 'modify',
            Component: lazy(
              () => import('../views/userInfo/components/operate')
            )
          },
          {
            path: 'certification',
            Component: lazy(() => import('../views/certification')),
            children: [
              {
                path: 'create',
                Component: lazy(
                  () => import('../views/certification/components/create')
                )
              },
              {
                path: 'fail',
                Component: lazy(
                  () => import('../views/certification/components/fail')
                )
              },
              {
                path: 'audit',
                Component: lazy(
                  () => import('../views/certification/components/audit')
                )
              },
              {
                path: 'verified',
                Component: lazy(
                  () => import('../views/certification/components/verified')
                )
              }
            ]
          },
          {
            path: 'notice',
            Component: lazy(() => import('../views/notice'))
          },
          {
            path: 'upload',
            Component: lazy(() => import('../views/upload'))
          },
          {
            path: 'update',
            Component: lazy(
              () => import('../views/upload/components/basicInfo')
            )
          },
          {
            path: 'success',
            Component: lazy(() => import('../views/upload/components/submit'))
          },
          {
            path: '',
            redirect: 'home'
          }
        ]
      }
    ]
  },
  {
    path: '*',
    Component: lazy(() => import('../views/404'))
  }
];

export default routers;
