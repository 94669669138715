import React from 'react';
import { Spin } from 'antd';
import './index.scss';

/**
 * @description loading页面
 *
 * @returns {JSX.Element} JSX.Element
 */
const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <Spin tip="Loading..." />
    </div>
  );
};
export default Loading;
