import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userLoginInfo {
  uid?: string;
  apiToken?: string;
}

interface BaseInfo {
  uid?: string;
  apiToken?: string;
  uname?: string;
  avatarUrl?: string;
  phone?: string;
  email?: string;
  password?: string;
}
interface UserInfo {
  baseInfo?: BaseInfo;
  banInfo?: any;
}

interface UserStatus {
  isPhone?: boolean;
  isProject?: boolean;
  isOpenReward?: boolean;
}
export interface CounterState {
  userStatus: UserStatus;
  userLoginInfo: userLoginInfo;
  userInfo: UserInfo;
}

const initialState: CounterState = {
  userStatus: {},
  userLoginInfo: {},
  userInfo: {}
};

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setUserLoginInfo: (state, action) => {
      state.userLoginInfo = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  }
});

export const { setUserStatus, setUserLoginInfo, setUserInfo } =
  counterSlice.actions;

export default counterSlice.reducer;
