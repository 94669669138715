(function () {
  if (typeof TextEncoder == 'function') {
    return;
  }

  function _TextEncoder() {
    //--DO NOTHING
  }
  _TextEncoder.prototype.encode = function (s) {
    //return unescape(encodeURIComponent(s)).split('').map(function(val) {return val.charCodeAt();});
    const data = unescape(encodeURIComponent(s))
      .split('')
      .map(function (val) {
        return val.charCodeAt();
      });
    return typeof Uint8Array == 'function' ? new Uint8Array(data) : data; //new TextEncoder().encode返回Uint8Array
  };
  function _TextDecoder() {
    //--DO NOTHING
  }
  _TextDecoder.prototype.decode = function (code_arr) {
    return decodeURIComponent(
      escape(String.fromCharCode.apply(null, code_arr))
    );
  };

  window.TextEncoder = _TextEncoder;
  window.TextDecoder = _TextDecoder;
})();
