import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  breadcrumb: string[]; // 面包屑数据
}

const initialState: State = {
  breadcrumb: []
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb: (state, action) => {
      state.breadcrumb = action.payload;
    }
  }
});

export const { setBreadcrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
